<template>
<CRow>
  <CCol sm="6">
    <CCard bodyWrapper>
      <CRow>
        <CCol sm="12">
          <h4>LMS ที่ต้องการเปิด</h4>
          <CSelect
            :options="['KC-Moodle', 'Canvas', 'Mango', 'Exam']"
            placeholder="เลือก LMS..."
            @update:value="lmsSelected"
            size="lg"
          />
        </CCol>
        <CCol sm="6">
          <h4>เทอม</h4>
          <CSelect
            :options="['1', '2', '3']"
            placeholder="เลือกเทอม"
            @update:value="termSelected"
            size="lg"
          />
        </CCol>
        <CCol sm="6">
          <h4>ปีการศึกษา (ตัวอย่าง 2564)</h4>
          <CInput
            type="text"
            placeholder="ป้อนรหัสกระบวนวิชา..."
            maxlength=6
            size="lg"
            @update:value="yearSelected"
          />
        </CCol>
        <CCol sm="12">
          <h4>รหัสกระบวนวิชา 6 หลัก</h4>
          <CInput
            type="text"
            placeholder="ป้อนรหัสกระบวนวิชา..."
            maxlength=6
            size="lg"
            @update:value="courseCodeSelected"
          />
        </CCol>
        <CCol sm="12">
          <h4>ชื่อคอร์สหรือชื่อกระบวนวิชา</h4>
          <CInput
            type="text"
            placeholder="ป้อนชื่อกระบวนวิชา..."
            size="lg"
            @update:value="courseNameSelected"
          />
        </CCol>
        <CCol sm="12">
          <h4>เลข section คั่นด้วย comma (ตัวอย่าง 001000,002000,000...)</h4>
          <CInput
            type="text"
            placeholder="ป้อนเลข Section..."
            size="lg"
            @update:value="sectionSelected"
          />
        </CCol>
        <CCol sm="12" v-if="fromCreateCourse.lmsType === 'KC-Moodle' || fromCreateCourse.lmsType === 'Exam'">
          <h4>เลข Category Id</h4>
          <CInput
            type="text"
            placeholder="ป้อนเลข Category..."
            size="lg"
            @update:value="sectionSelected"
          />
        </CCol>
        <CCol sm="12" v-if="fromCreateCourse.lmsType === 'KC-Moodle' || fromCreateCourse.lmsType === 'Exam'">
          <h4>เลข Category Id</h4>
          <CInput
            type="date"
            placeholder="ป้อนเลข Category..."
            size="lg"
            @update:value="sectionSelected"
          />
        </CCol>
        <CCol sm="12">
          <h4>Email อาจารย์ที่ต้องการให้เป็นเจ้าของ</h4>
          <CInput
            type="email"
            placeholder="ป้อน CMU IT ACCOUNT..."
            size="lg"
            @update:value="emailSelected"
          />
        </CCol>
      </CRow>
      <CRow class="pt-3">
        <CCol>
          <CButton size="lg" @click="submitCreateCourse" color="pastelpurple2">สร้าง</CButton>
        </CCol>
      </CRow>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
import Api from "@/services/Api"
import MoodleApi from "@/services/MoodleApi"
import CanvasApi from "@/services/CanvasApi"
import MangoApi from "@/services/MangoApi"
import ExamApi from "@/services/ExamApi"

import Swal from 'sweetalert2'
export default {
  name: 'CreateCourse',
  data(){
    return {
      originEmail:localStorage.getItem("email"),
      newEmail: "",
      fromCreateCourse: {
        lmsType: "",
        term: "",
        year: "",
        courseCode: "",
        courseName: "",
        sectionPlainText: "",
        ownerEmail: ""
      }
    }
  },
  created() {
    console.log("Create Course")
    this.checkGrant(localStorage.getItem('email'))
  },
  methods: {
    emailUpdate (email) {
      this.newEmail = email
    },
    lmsSelected (selected) {
      this.fromCreateCourse.lmsType = selected
    },
    termSelected (selected) {
      this.fromCreateCourse.term = selected
    },
    yearSelected (selected) {
      this.fromCreateCourse.year = selected
    },
    courseCodeSelected (selected) {
      this.fromCreateCourse.courseCode = selected
    },
    courseNameSelected (selected) {
      this.fromCreateCourse.courseName = selected
    },
    sectionSelected (selected) {
      this.fromCreateCourse.sectionPlainText = selected
    },
    emailSelected (selected) {
      this.fromCreateCourse.ownerEmail = selected
    },
    submitCreateCourse () {
      console.log(this.fromCreateCourse)
      if(this.fromCreateCourse.lmsType !== "" && this.fromCreateCourse.term !== "" && this.fromCreateCourse.year !== "" && this.fromCreateCourse.courseCode !== "" && this.fromCreateCourse.sectionPlainText !== "" && this.fromCreateCourse.ownerEmail !== "" ){
        Swal.fire(
          'คำเตือน!',
          'ในเวอร์ชั่นปัจจุบันการเปลี่ยน Email เป็นผู้ใช้งานอื่นจะไม่สามารถกลับเปลี่ยนมาเป็น Email ผู้ใช้งานเดิมได้ นอกจากการ Logout และ Login อีกครั้ง จึงขอแนะนำให้ใช้งานฟังก์ชั่นนี้ในโหมดไม่ระบุตัวตน',
          'warning'
        )
        switch (this.fromCreateCourse.lmsType) {
          case "KC-Moodle":
            this.createMoodle(this.fromCreateCourse)
            break;
          case "Canvas":
            
            break;
          case "Mango":
            
            break;
          case "Exam":
            
            break;
          default:
            break;
        }
      }
    },
    createMoodle (courseData) {
      let apiData = ({
        "term": courseData.term,
        "year": courseData.year,
        "term_start": courseData,
        "term_end": courseData,
        "regCourse_selected_id": courseData.courseCode,
        "regCourse_selected_name": courseData.courseName,
        "category_selected": courseData,
        "sectionArr": courseData,
        "create_by": {
            "cmuitaccount_name": courseData,
            "cmuitaccount": courseData,
        }
      })
    },
    async checkGrant(email){
      let grantList = await Api.checkGrant(email)
      let foundAdminGrant = false
      if(grantList){
        console.log("grantList(API):",grantList)
        for(let i=0;i<grantList.length;i++){
          if(grantList[i].grant_on === "admin"){
            foundAdminGrant = true
            break
          }
        }
        if(!foundAdminGrant){
          this.$router.push({path: `/`})
        }
      }
    },
  }
}
</script>
