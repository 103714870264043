import {LMS} from "@/axios.js"

LMS.defaults.timeout = 600000

export default {

    async getExamListByUser(cmuitaccount){
      let response = await LMS.get(`api/GetExamListByUser?cmuitaccount=${cmuitaccount}`)
      .then(res => {
        if (res.data) {
          // console.log("getCourseByOwner(API Service):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async manualEnrollExam(data){
      console.log(data)
      var response = await LMS.post(`api/ManualEnrollExam`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("ManualEnrollExam(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async manualSyncExam(data){
      console.log(data)
      var response = await LMS.post(`api/ManualSyncExam`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("ManualSyncExam(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async updateExamCourse(data){
      console.log(data)
      var response = await LMS.post(`api/ExamUseOldCourse`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("ExamUseOldCourse(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async creatExamCourse(data){
      console.log(data)
      var response = await LMS.post(`api/CreateExam`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("creatExamCourse(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async getEnrollStatus(cmuitaccount){
      let response = await LMS.get(`api/GetEnrollStatus?cmuitaccount=${cmuitaccount}`)
      .then(res => {
        if (res.data) {
          // console.log("getCourseByOwner(API Service):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async getEnrollStatusByLms(lmsType, courseId){
      let response = await LMS.get(`api/GetEnrollStatusByLms?lms_type=${lmsType}&course_id=${courseId}`)
      .then(res => {
        if (res.data) {
          console.log("getEnrollStatusByLms(API Service):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },

}