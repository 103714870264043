import {LMS} from "@/axios.js"

LMS.defaults.timeout = 600000

export default {
    async getCanvasListByUser(cmuitaccount){
        let response = await LMS.get(`api/GetCanvasListByUser?cmuitaccount_name=${cmuitaccount}`)
        .then(res => {
          if (res.data) {
            // console.log("getCourseByOwner(API Service):",res.data);
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },
    async getEnrollStatusByLms(lmsType, courseId){
        let response = await LMS.get(`api/GetEnrollStatusByLms?lms_type=${lmsType}&course_id=${courseId}`)
        .then(res => {
          if (res.data) {
            console.log("getEnrollStatusByLms(API Service):",res.data);
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },
    async creatCanvasCourse(data){
        console.log(data)
        let response = await LMS.post(`api/CreateCanvas`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
                console.log("creatCanvasCourse(API):",res.data)
                return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async manualEnrollCanvas(data){
      console.log(data)
      var response = await LMS.post(`api/ManualEnrollCanvas`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("ManualEnrollCanvas(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async manualSyncCanvas(data){
      console.log(data)
      var response = await LMS.post(`api/ManualSyncCanvas`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("ManualSyncCanvas(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async updateCanvasCourse(data){
      console.log(data)
      var response = await LMS.post(`api/CanvasUseOldCourse`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("CanvasUseOldCourse(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
}